<template>
  <locale-router-link :to="`news/${item.id}`" class="news__item">
    <img v-lazy="getNewsImg()" alt="News image" class="news__img" />
    <p class="news__date">
      {{ getDate(item) }}
    </p>
    <h3 class="news__item-title">
      {{ item.attributes.Header }}
    </h3>
  </locale-router-link>
</template>

<script>
export default {
  name: "NewsCard",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localMonth: {
        января: "yanvar",
        февраля: "fevral",
        марта: "mart",
        апреля: "aprel",
        мая: "may",
        июня: "iyun",
        июля: "iyul",
        августа: "avgust",
        сентября: "sentyabr",
        октября: "oktyabr",
        ноября: "noyabr",
        декабрь: "dekabr",
      },
    };
  },
  methods: {
    getNewsImg() {
      const imgUrl = this.item?.attributes?.image?.data?.attributes?.url;
      if (imgUrl) {
        return process.env.VUE_APP_API_URL + imgUrl;
      }
      return require("@/" + "assets/img/icons/placeholder.svg");
    },
    getDate(item) {
      const dateFromStr = new Date(item.attributes.publishedAt);
      let dateRU = new Intl.DateTimeFormat("ru-Ru", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(dateFromStr);
      let date = dateRU.split(" ");
      let dateUZ = date[0] + " " + this.localMonth[date[1]] + " " + date[2];
      if (this.$i18n.locale === "uz") {
        return dateUZ;
      } else {
        return dateRU;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
